@tailwind components;

@layer components {
  .error-page-background {
    @apply w-full
    min-h-screen
    h-full
    flex
    justify-center
    items-center
    md:p-8
    p-4
    fixed
    bg-[#FAFAFA];
  }

  .error-page-content-container {
    @apply flex
    flex-col
    items-center
    justify-center
    gap-y-8
    h-full
    overflow-hidden;
  }

  .error-page-img-dimentions {
    @apply lg:w-4/12
    md:w-6/12
    w-8/12;
  }

  .error-page-btn {
    @apply rounded-[15px]
    px-11
    py-4
    !bg-third-color-05
    sm:text-xl
    text-base
    font-bold
    text-third-color
    !w-fit
    hover:!bg-[#414e5f]/10
    transition-colors
    cursor-pointer;
  }

  .maintenance-page-content-container {
    @apply flex
    flex-col
    items-center
    justify-center
    overflow-hidden;
  }

  .maintenance-page-logo-dimentions {
    @apply lg:w-1/6
    md:w-2/6
    w-3/6;
  }

  .maintenance-page-img-dimentions {
    @apply lg:w-3/6
    md:w-4/6
    w-5/6
    mt-6;
  }

  .maintenance-page-text-container {
    @apply w-full
    text-center
    md:mt-2
    mt-1;
  }

  .maintenance-page-title {
    @apply md:text-[30px]
    text-xl
    font-black;
  }

  .maintenance-page-sub-title {
    @apply md:text-xl
    text-base
    opacity-70
    tracking-[0.2px];
  }
}
