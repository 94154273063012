@tailwind components;

@layer components {
  .auth-layout-container {
    @apply grid 
      relative 
      bg-main-color 
      items-center 
      h-full
      min-h-screen
      bg-cover 
      bg-no-repeat 
      content-center 
      justify-items-center;
  }

  .auth-layout-img {
    @apply absolute
      opacity-[0.1]
      w-full
      h-full
      bg-cover;
  }

  .auth-layout-box-content {
    @apply grid 
      mx-2
      gap-8
      rounded-[30px]
      p-8
      bg-white 
      z-20
      md:w-[50%]
      lg:w-[35%];
  }
  .auth-head-container {
    @apply grid
      px-2.5
      text-center
      gap-2.5
      justify-items-center;
  }
  .auth-card-title {
    @apply text-3xl 
      font-black;
  }
  .auth-card-description {
    @apply text-[#7A7A7A]
      text-lg
      font-normal;
  }

  .auth-image-forget-password {
    @apply w-64
      h-52
      m-auto;
  }
  .forget-link {
    @apply text-main-color
      text-xs
      font-semibold
      hover:underline;
  }

  .auth-card-footer {
    @apply relative 
      grid
      justify-items-center
      content-center
      text-center
      w-[70%];
  }
  .auth-card-footer-container {
    @apply flex
      justify-center 
      items-center;
  }

  /* verify code  */
  .verify-code-inputs-box {
    @apply grid 
      grid-cols-4 
      gap-5
      px-44;
  }
  .verify-code-input {
    @apply w-14
      h-14
      text-center
      text-2xl
      font-bold	
      bg-light-gray
      rounded-primary-radius
      outline-none;
  }
  .verify-code-sub-footer {
    @apply px-3.5
      text-sm
      font-normal
      opacity-70
      text-center
      py-3;
  }
  .verify-code-sub-footer-link {
    @apply text-base
      ml-1
      leading-5
      font-bold
      text-main-color;
  }
  /* forget password */
  .auth-head-container-forgetpassword-span {
    @apply flex
      justify-start
      w-full 
      mr-8;
  }
  .container-span {
    @apply flex
      flex-row
      justify-center
      items-center
      bg-light-gray-color
      gap-3 
      py-2
      px-3
        rounded-[8px]
        w-full;
  }
  .auth-card-footer-container {
    @apply flex
      justify-center
      items-center;
  }
  .span-text {
    @apply text-third-color
      text-base
      font-semibold;
  }
  .form-forgetpassword {
    @apply flex 
      flex-col 
      gap-10
      mb-8;
  }
  /* login */
  .form-login {
    @apply flex
      flex-col 
      gap-8;
  }
  .auth-head-container-login-span {
    @apply flex
      justify-end
      w-full 
      ml-8;
  }
}
