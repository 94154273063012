.selectInputWithBtnContainer {
  @apply focus-within:outline
    outline-main-color
    outline-2
    flex
    justify-between
    p-[5px]
    bg-input-color
    /* dark:bg-gray-800 */
    rounded-primary-radius
    w-full;
}

.selectInputWithBtnInput {
  @apply bg-transparent
    md:px-4
    px-2
    py-1
    border-none
    placeholder:text-[14px]
    placeholder:leading-[20px]
    placeholder:font-[400]
    text-gray-900
    outline-none
    focus:outline-none
    w-full
    /* dark:text-white*/
    /* dark:caret-white */;
}
