@tailwind components;

@layer components {
  .action-btn-modal {
    @apply absolute
      bg-white
      z-40
      mt-2
      rounded-modal-radius
      shadow-[0_0_15px_0_rgba(0,0,0,0.20)]
      w-56 
      ltr:right-0
      rtl:left-0 
      overflow-hidden
      duration-300
    /* dark:bg-gray-900 */;
  }

  .action-btn-listItem {
    @apply cursor-pointer
    duration-300
    px-5
    flex
    items-center
    h-[4rem]
    hover:bg-gray-500/10
    text-base
    font-light
    opacity-70
    gap-2.5
    w-full;
  }

  .action-btn-listItem-link {
    @apply w-full
    h-full
    flex
    justify-start
    items-center
    gap-x-2.5;
  }
}
