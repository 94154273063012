@tailwind components;

@layer components {
  .add-discount-btn {
    @apply !bg-main-color
    !whitespace-nowrap
    !px-2
    !text-white
    !h-10
    !w-full
    md:!w-[151px]
    !mt-5
    md:!mt-0;
  }

  .export-btn {
    @apply !bg-third-color
    !text-white
    !w-[90px]
    md:!w-[123px]
    !h-[39px]
    !flex
    !text-sm
    !font-semibold;
  }

  .discount-parent-container {
    @apply mt-8
    p-[30px]
    w-full
    h-fit
    gap-y-[30px]
    flex
    flex-col
    rounded-[20px]
    bg-white
    shadow-[0_0_15px_0_rgba(0,0,0,0.05)]
    flex-shrink-0;
  }

  .is_action-description {
    @apply text-sm
    text-black/70
    ml-1
    !whitespace-normal;
  }

  .discount-form {
    @apply flex
    flex-col
    gap-y-[30px];
  }

  .discount-first-row-grid {
    @apply grid
    grid-cols-2
    gap-x-[22px];
  }

  .discount-second-row-grid {
    @apply grid
    grid-cols-4
    gap-x-5
    gap-y-[30px];
  }

  .discount-checkbox {
    @apply md:col-span-2
    col-span-4
    flex
    items-end
    ml-2;
  }

  .discount-checkbox-flex {
    @apply flex
    items-start
    whitespace-nowrap
    h-11;
  }
}
