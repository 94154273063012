@tailwind components;

@layer components {
  .notify-container {
    @apply fixed
      z-50
      md:max-w-sm
      p-4
      md:p-4
      max-h-screen
      overflow-hidden
      pointer-events-none;
  }
  .notify-container-box {
    @apply flex
      py-1
      w-full
      transform
      transition-all
      duration-300
      pointer-events-auto;
  }
  .notify-msg-container {
    @apply min-w-[311px]
      visible
      flex
      flex-row
      /* flex-no-wrap */
      gap-[22px]
      py-4
      px-[30px]
      shadow-lg
      border-l-4
      rounded-2xl
      duration-100
      cursor-pointer
      transform
      transition-all
      hover:scale-100;
  }
  .notify-msg-container-icon {
    @apply flex
      items-center
      /* h-12
      w-12 */
      /* mx-auto */
      /* text-xl */
      select-none;
  }
  .notify-msg-content {
    @apply flex
      flex-col
      px-1
      w-full;
  }
  .notify-msg-head {
    @apply flex
      my-auto
      text-lg
      font-bold
      select-none;
  }
  .notify-msg-description {
    @apply -mt-0.5
      my-auto
      break-all
      text-base
      font-normal
      flex
      /* text-gray-600  */;
  }
  .notify-msg-close-container {
    @apply w-10
      h-12
      items-center
      text-center
      leading-none
      text-lg;
  }
  .notify-msg-close-icon {
    @apply h-6 w-6 mx-auto
      my-auto
      text-center
      text-gray-600
      cursor-pointer
      hover:scale-105
      transform;
  }
  /* positions */
  .top_left {
    @apply top-0
      left-6;
  }
  .top_right {
    @apply top-0
      right-6;
  }
  .bottom_right {
    @apply bottom-0
      right-0;
  }
  .bottom_left {
    @apply bottom-0
      left-0;
  }
  .top_middle {
    @apply top-0
      left-1/2
      -translate-x-1/2
      transform;
  }
  .bottom_middle {
    @apply bottom-0
      left-1/2
      -translate-x-1/2
      transform;
  }
  .undefined {
    @apply top-0
      right-0;
  }
}
