@tailwind components;

@layer components {
  .input-date {
    @apply 
      flex-1 
      p-2 
      outline-none 
      text-base 
      bg-input-color 
      rounded-primary-radius
      text-black 
      opacity-30
      cursor-pointer
      w-full;
  }
}
