@tailwind components;

@layer components {
  .table-status-container {
    @apply relative
      leading-tight
      w-fit
      flex
      justify-center
      text-sm
      items-center;
  }

  .table-status-success,
  .table-status-failure {
    @apply w-[89px]
      py-2
      text-center
      rounded-primary-radius
      /* bg-gray-color */
      text-success-color
      bg-success-color-05;
  }

  .table-status-failure {
    @apply text-failure-color
      bg-success-failure-05;
  }
}
