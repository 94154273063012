@tailwind components;

@layer components {
  .table-container {
    @apply pt-[30px]
      text-sm;
  }
  .table-status-container {
    @apply relative
      leading-tight
      flex
      justify-center
      items-center;
  }

  .table-status-success,
  .table-status-failure {
    @apply w-[89px]
      py-2
      text-center
      rounded-primary-radius
      /* bg-gray-color */
      text-success-color
      bg-success-color-05
      font-normal;;
  }

  .table-status-failure {
    @apply text-failure-color
      bg-success-failure-05;
  }
}
