.selected-language-container {
  @apply flex
    items-center
    justify-center
    gap-x-3
    cursor-pointer
    relative
    py-2
    px-4  
    rounded-lg
    transition-all
    duration-200
    ease-linear
    outline-1
    outline;
}

.selected-language {
  @apply text-primary-color
    text-sm
    font-bold;
}

.selected-language-background {
  @apply absolute
    w-full
    h-full
    bg-primary-color
    opacity-5
    rounded-lg;
}

.language-list-container {
  @apply absolute
    shadow-[0_0_15px_0_rgba(0,0,0,0.07)]
    cursor-pointer
    right-0
    top-12
    rounded-lg
    py-2
    px-4
    w-36
    sm:w-40
    bg-white
    h-fit
    flex
    flex-col
    gap-y-2
    transition-all
    duration-200
    ease-linear;
}

.language-option-container {
  @apply flex
    items-center
    gap-x-4
    h-10
    justify-between;
}

.language-divider {
  @apply h-[1px]
    bg-black/5
    w-full;
}
