@tailwind components;

@layer components {
  .table-header-selector {
    @apply lg:pb-6
      pb-4
      gap-2.5
      border-b
      border-gray-100
      w-full
      flex
      flex-col
      md:flex-row
      md:justify-between
      p-4
      md:p-[30px]
      rounded-tl-2xl 
      rounded-tr-2xl
      /* shadow-md */
      /* sm:px-6 */
      /* sm:pt-6 */
      /* dark:border-gray-500 */;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  }

  .table-header-search {
    @apply w-auto
      md:w-full
      /* col-span-3 
      row-span-1; */;
  }

  .table-header-filter-box {
    @apply gap-5
      flex
      justify-between
      md:justify-items-end;
  }

  .table-header-selector-filter {
    @apply col-span-1 
      col-start-1 
      lg:col-start-6 
      row-start-2
      lg:row-span-1;
  }

  .table-header-selector-sort {
    @apply col-span-1 
      lg:col-start-7 
      col-start-2 
      row-start-2
      lg:row-span-1;
  }

  .table-header-selector-btn {
    @apply col-span-1 
      lg:col-start-8 
      col-start-3
      row-start-2
      lg:row-span-1;
  }

  .table-header-selector {
    @apply lg:col-span-1
      col-span-2
      relative
      z-10;
  }

  .table-header-button {
    @apply w-full
      col-span-1
      relative
      cursor-pointer
      rounded-primary-radius
      md:h-14
      h-14;
  }
  .filter-drop-footer {
    @apply w-full
      pt-3
      flex
      justify-end;
  }
}
