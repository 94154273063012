@tailwind components;

@layer components {
  .header-item-btn-delete,
  .header-item-btn-update,
  .header-item-btn-add-item {
    @apply bg-main-color
        text-white
        text-sm
        font-normal
        rounded-primary-radius
        !w-[116px]
        !h-[41px]
        flex
        justify-center 
        items-center;
  }
  .header-item-btn-delete {
    @apply !bg-gray-color-5
        !text-third-color
        !w-[100px];
  }
  .header-item-btn-add-item {
    @apply w-[117px]
        md:w-[151px]
        mt-5
        md:mt-0;
  }
  .item-setting-export-btn {
    @apply !bg-third-color
      text-white
      !w-[90px]
      md:!w-[123px]
      h-[39px]
      flex
      text-sm
      font-semibold;
  }
  .item-setting-save-add-item-btn {
    @apply !bg-main-color
      w-[117px]
      h-[41px]
      text-white
      text-sm
      font-semibold;
  }
  .item-setting-update-item-btn {
    @apply !bg-main-color
      w-[117px]
      h-[41px]
      text-white
      text-sm
      font-semibold;
  }
}
