@tailwind components;

@layer components {
  .header-cell {
    @apply text-start
      font-semibold
      text-black
      /* dark:text-white */;
  }
}
