@tailwind components;

@layer components {
  .navlinks-container {
    @apply w-full
      flex
      justify-between
      flex-col
      md:flex-row
      text-opacity-70 
      px-[1px]
      mb-8
      /* dark:text-white */;
  }
  .navlinks-text-container {
    @apply flex
      justify-start 
      flex-col gap-1;
  }
  .navlinks-text {
    @apply flex
      items-center
      gap-3 capitalize;
  }
  .navlinks-path {
    @apply font-bold
      text-light-black-color-08
      text-xl
      flex
      items-center
      gap-4
    /* pointer-events-none */
    /* dark:text-white  */;
  }
  .navlinks-path-arrow {
    @apply text-sm
      font-normal
      cursor-pointer
      rtl:rotate-180
      hover:scale-110;
  }
  .navlinks-header {
    @apply flex
      items-center
      gap-3
      text-light-black-color-07
      opacity-80
      /* font-title-font; */;
  }
  .navlinks-header-text {
    @apply text-light-black-color
      text-sm
      font-light
      opacity-70
    /* dark:text-white */;
  }
  .navlink,
  .navlink-disable {
    @apply capitalize
      m-1
      font-bold
      text-sm
      /* dark:text-white */
      /* hover:underline; */;
  }
  .navlink-disable {
    @apply font-normal;
  }
}
