@tailwind components;

@layer components {
  .cell-role {
    @apply bg-gray-color-2
      w-[95px]
      text-center
      py-2
      font-normal
      rounded-primary-radius
      inline-block;
  }
}
