@tailwind components;

@layer components {
  .profile-container form {
    @apply rounded-[20px]
        p-7
        space-y-7
        bg-white
        duration-300
        shadow-md
        w-full
   /* dark:bg-gray-900 */;
  }
}
