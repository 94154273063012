@keyframes spin-reverse {
  from {
    transform: rotate(360deg);
  }
}

.animate-reverse-spin {
  animation: spin-reverse 1s linear infinite;
}

.loading-spinner-container {
  @apply w-full
  h-full
  text-center
  flex
  flex-col
  justify-center
  items-center;
}

.loading-spinner-svg {
  @apply h-full
  absolute
  top-0
  left-0;
}
