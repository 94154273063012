@tailwind components;

@layer components {

  .time-duration-text {
    @apply
    ltr:mr-1 rtl:ml-1
    text-gray-600
    dark:text-gray-400
    whitespace-nowrap;
  }
}
