@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .tabs-container {
    @apply flex
      space-x-3
      border-b
      bg-fourth-color;
  }
  .tabs-btn {
    @apply grow
      inline-block
      px-4
      py-3;
  }
  .tabs-btn-active {
    @apply !bg-primary-color
      rounded
      text-white;
  }
  .tabs-btn-nonactive {
    @apply text-black
      border-transparent
     hover:border-gray-200;
  }
}
