@tailwind components;

@layer components {
  .bottom-tooltip {
    @apply absolute
    flex
    items-center
    justify-center
    z-10
    inline-block
    px-2.5
    py-1.5
    text-sm
    font-bold
    text-white
    bg-primary-color
    rounded-[5px]
    shadow-sm
    mt-1
    duration-300;
  }

  .bottom-tooltip-arrow {
    @apply absolute
    bottom-6
    bg-primary-color
    rounded-[3px]
    rotate-45
    w-3
    h-3;
  }
}
