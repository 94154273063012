@tailwind components;

@layer components {
  .columns-view-modal {
    @apply absolute
      bg-white
      p-5
      z-40
      mt-2
      rounded-primary-radius
      duration-300
      /* min-w-72 */
      /* dark:bg-gray-900 */;
  }
  .columnsview-text {
    @apply text-sm
      font-normal
      bg-white;
  }
  .columnsview-map {
    @apply grid-cols-2 
      gap-5;
  }
  .columnsview-button {
    @apply flex justify-end 
      gap-2 mt-5;
  }
}
