@tailwind components;

@layer components {
  .membership-payments-card {
    @apply overflow-y-hidden
    w-full
    md:p-[30px]
    p-5
    h-fit
    bg-white
    rounded-[20px]
    shadow-[0_0_15px_0_rgba(0,0,0,0.05)];
  }

  .membership-payments-danger-message {
    @apply flex
    md:flex-row
    flex-col
    items-center
    gap-x-5
    gap-y-3
    bg-[#FF0000]/[0.03]
    md:text-start
    text-center
    

    md:px-[30px]
    px-5
    md:py-5
    py-3
    rounded-[10px];
  }

  .membership-payments-danger-icon {
    @apply md:w-[50px]
    md:h-[50px]
    w-10
    h-10
    text-failure-color;
  }

  .membership-payments-danger-text-container {
    @apply flex
    md:gap-y-[10px]
    gap-y-1.5
    flex-col
    h-full
    items-start
    justify-between;
  }

  .membership-summary-card {
    @apply md:p-[30px]
    p-5
    !pb-0
    w-full
    lg:max-w-[373px]
    min-w-[300px]
    h-fit
    bg-white
    rounded-[20px]
    shadow-[0_0_15px_0_rgba(0,0,0,0.05)];
  }

  .membership-change-sub-plan-btn {
    @apply w-full
    !bg-primary-color
    text-white
    font-bold
    md:text-base
    py-3.5
    rounded-[10px]
    text-sm;
  }

  .cancel-membership {
    @apply text-sm
    font-semibold
    text-[#CC0000]
    opacity-80
    w-full
    text-center
    my-5
    cursor-pointer
    transition-all
    duration-300;
  }

  .center {
    @apply flex
    items-center
    justify-between;
  }

  .membership-titles {
    @apply text-lg
    font-bold
    opacity-90;
  }

  .membership-sub-titles {
    @apply text-sm
    mt-1;
  }

  .membership-table-container {
    @apply pt-[30px]
    md:-mb-8
    -mb-5;
  }

  .membership-table-css {
    @apply !p-0
    !shadow-none
    !rounded-none;
  }

  .membership-payments-danger-title {
    @apply font-bold
    text-failure-color
    whitespace-nowrap
    w-full;
  }

  .membership-payments-danger-sub-title {
    @apply text-sm
    text-failure-color
    opacity-80;
  }

  .membership-page-container {
    @apply w-full
    flex
    lg:flex-row
    flex-col
    gap-5
    mt-6;
  }
  .title {
    @apply text-light-black-color
      text-xl
      font-medium
      opacity-80;
  }
  .text-title {
    @apply text-light-black-color
      text-sm
      font-light
      opacity-70;
  }
  .cancel-membership-hover-effect:hover {
    /* cc0000 failure color "Red" */
    text-shadow: -1px -1px 10px rgba(204, 0, 0, 0.1),
      1px -1px 10px rgba(204, 0, 0, 0.1), -1px 1px 10px rgba(204, 0, 0, 0.1),
      1px 1px 10px rgba(204, 0, 0, 0.1);
  }
}
