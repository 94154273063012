@tailwind components;

@layer components {
  /* span {
    @apply text-base
    font-medium;
  } */
  .title {
    @apply text-light-black-color
      text-xl
      font-medium
      opacity-80;
  }
  .text-title {
    @apply text-light-black-color
      text-sm
      font-light
      opacity-70;
  }
  .setting-description {
    @apply text-sm
      font-light
      opacity-50;
  }
  .setting-container {
    @apply grid 
      gap-5;
  }

  .items {
    @apply flex 
      flex-row 
      items-center  
      gap-4;
  }
  .items-container {
    @apply bg-white
      cursor-pointer
      p-2.5
      shadow-md
      rounded-xl
      w-full
      transition
      duration-300
      ease-in-out
      hover:drop-shadow-xl
    /* dark:bg-gray-900 */;
  }
  .items-col {
    @apply flex 
      flex-col;
  }
  .setting-flex-center {
    @apply p-5 
      text-primary-color
      bg-fourth-color
      rounded-primary-radius;
  }
}
