.add-role-save-btn {
  @apply w-fit
    h-fit
    px-8
    py-2.5
    hover:!bg-pink-950
    !bg-primary-color
    text-white;
}
.add-role-title-row {
  @apply grid
    md:grid-cols-2
    grid-cols-1
    w-full
    gap-x-7
    gap-y-3
    border-b
    pb-7
    border-gray-200/50
  /* dark:border-gray-200/20 */;
}
.add-role-is-active-label {
  @apply text-black
    text-opacity-70
    font-light
  /* dark:text-white/80 */;
}
.add-role-permissions-title {
  @apply text-black
    text-opacity-70
    font-bold
    md:mb-5
    mb-4
  /* dark:text-white/90 */;
}
.add-role-permission-child-container {
  @apply grid
    grid-cols-1
    lg:grid-cols-2
    gap-x-7
    md:gap-y-7
    gap-y-5;
}
.add-role-check-grid-container {
  @apply grid
    md:grid-cols-2
    grid-cols-1
    w-full
    md:gap-y-9
    gap-y-4
    gap-x-5;
}
.add-role-rule-square {
  @apply bg-white
    rounded-[10px]
    md:px-8
    px-6
    md:py-5
    py-4
    w-full
  /* dark:bg-white/5 */;
}
.add-role-divider {
  @apply h-[1px]
    md:mt-[18px]
    mt-3
    md:mb-5
    mb-3
    -mx-2
    border-none
    bg-gray-200/50
  /* dark:bg-gray-200/10 */;
}
.add-role-top-container {
  @apply bg-white
    rounded-primary-radius
    p-7
    space-y-7
  /* dark:bg-gray-900 */;
}
.add-role-permissions-container {
  @apply bg-input-color
    rounded-[20px]
    md:p-7
    p-5
  /* dark:bg-white/5 */;
}
.add-role-check-box {
  @apply bg-gray-color 
    opacity-50
    checked:bg-secondary-color
    checked:opacity-100
    scale-110
  /* checked:dark:bg-dark-primary-color */;
}
