@tailwind components;

@layer components {
  .text {
    @apply text-base
      font-semibold;
  }
  .text-title {
    @apply text-base
      font-light
      text-light-black-color
      opacity-70;
  }
  .text-head-title {
    @apply text-xl
      font-medium
      text-light-black-color
      opacity-80;
  }
  .head-row {
    @apply flex
      flex-row
      items-center
      gap-4;
  }
  .head-col {
    @apply flex
      flex-col
      items-center
      gap-4;
  }
  .head-col-start {
    @apply flex
      flex-col
      items-start
      gap-2;
  }
  .client-container {
    @apply bg-white
      duration-300
      sm:px-6
      sm:pt-6
      px-4
      pt-4
      shadow-md
      rounded-xl
      w-full
    /* dark:bg-gray-900 */;
  }
  .container {
    @apply flex
      flex-row
      justify-between
      items-center
      w-full;
  }
  .addclient-container {
    @apply grid 
     gap-8;
  }
  .client-container-border {
    @apply grid
      gap-8
      border-b-2
      py-6;
  }
  .client-container-col {
    @apply grid
      gap-8
      py-6;
  }
  .client-input {
    @apply grid
      grid-cols-2
      gap-8;
  }
  .client-tow-col {
    @apply grid
      grid-cols-2
      gap-8;
  }
  .client-tab {
    @apply col-span-2
      gap-5
      grid
      grid-rows-3
      gap-8;
  }
  .client-tab-container {
    @apply grid
      grid-cols-3
      gap-8;
  }
}
