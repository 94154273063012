@tailwind components;

@layer components {
  .cell-enum-box {
    @apply gap-y-1.5	
    ml-4
    capitalize	
    truncate
    flex
    justify-between
    flex-col;
  }
  .cell-enum-label {
    @apply text-base
      font-bold
      text-black
      opacity-90
      whitespace-nowrap;
  }
  .cell-enum-value {
    @apply text-sm
    font-normal	
    opacity-70
    whitespace-nowrap
      /* dark:text-main-color */;
  }
}
