@tailwind components;

@layer components {
  .table-container-header {
    @apply bg-white
      duration-300  
      rounded-[20px]
      w-full
    /* dark:bg-gray-900 */;
  }

  .table-container-table {
    @apply !-mb-px
      w-full
      px-4
      py-4
      overflow-y-hidden
      shadow-md
      md:px-[30px]
      rounded-b-[20px]
      overflow-x-auto;
  }

  .table-cell-container {
    @apply min-w-[204px] 
      max-w-[214px]
      pr-2 
      rtl:pl-2;
    /* min-w-[160px] 
      max-w-[170px]; */
  }

  .table-container-tbody {
    @apply w-full;
  }

  .table-footer {
    @apply mt-8
      md:mt-1
      flex 
      flex-col
      justify-center
      items-center
      sm:flex-row
      sm:justify-between;
  }
}
