@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,300;6..12,400;6..12,600;6..12,700;6..12,800;6..12,900&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #f5f5f5;
  font-family: "Nunito Sans";
}

:root {
  --main-color: #79194f;
  --second-color: #a73a7b;
  --third-color: #414e5f;
  --fourth-color: #f8f3f6;
  --light-gray-color: #f8f9f9;
  --gray-color: #d9d9d9;
  --light-gray-2: #7a7a7a;
  --light-gray-3: #f7f7f7;
  --failure-color: #cc0000;
  --light-failure-color: #fdf7f7;
  --success-color: #4bb543;
  --input-color: #f9f9f9;
  --dark-main-color: #349292;
  --light-black-color: #21272a;
  --warning-color: #f7ba02;
  --success-msg-color: #00b98b;
  --error-msg-color: #e35256;
  --checkbox-disabled-color: #adadad;
  --gray-color-5: #fdfdfd;
  --primary-color-3: #fbf8fa;

  --gray-color-2: #d9d9d933;
  --gray-color-5: #414e5f0d;
  --success-color-05: #4bb5430d;
  --success-failure-05: #e352560d;
  --warning-color-03: #f7ba0208;
  --failure-color-03: #c0000008;
  --light-black-color-07: #21272ab3;
  --light-black-color-08: #21272acc;
  --third-color-05: #414e5f0d;
  --black-color-07: #000000b3;
  --black-color-0: #000000b3;

  --primary-radius: 8px;
  --modal-radius: 10px;

  --title-font: "Kanit";
  --primary-color-5: #f8f3f6; 

  /*   
   --third-color: #414e5f; 
   --danger: #c00; 
   --primary-color: #791950;
  --primary-background-color: #79194f;
  --secondary-background-color: #f8f3f6;
  --third-color: #414e5f;
  --primary-radius: 8px;
  --input-color: #f9f9f9;
  --light-gray: #d9d9d9;
  --danger: #c00;
  --nav-color :#21272A; */
}

.invisible-scroll {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.invisible-scroll::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.layout {
  @apply lg:p-7;
}

.flex-center {
  @apply flex
    items-center
    justify-center;
}

.input-label {
  @apply text-lg
    font-bold
     /* dark:text-white; */;
}

.text-input {
  @apply duration-100
    border-0
    focus:outline-main-color
    placeholder:text-[14px]
    placeholder:leading-[21px]
    placeholder:font-[300]
    bg-input-color
    rounded-primary-radius
    py-[11px]
    pl-5
    pr-2
    w-full;
}

.icon-color {
  @apply opacity-30;
}

.divider {
  @apply h-px
    border-none
    opacity-50
    bg-gray-300/30
    /* dark:bg-gray-700/20; */;
}

@media (prefers-color-scheme: dark) {
  /* Change input color in autofill */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px rgb(31, 41, 55) inset;
    background-color: rgb(31, 41, 55);
    color: inherit;
    -webkit-text-fill-color: white !important;
    caret-color: white;
  }
}
