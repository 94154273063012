@tailwind components;

@layer components {
  .input-search-icon {
    @apply absolute
      inset-y-0
      left-0
      rtl:right-0
      flex
      items-center
      px-5
      pointer-events-none;
  }

  .input-search {
    @apply rounded-lg
      px-11
      p-2.5
      bg-input-color
      outline-main-color
      w-full
      md:w-44
      lg:w-[353px]
      h-10;
  }
}
