.add-role-save-btn {
  @apply w-[117px]
    h-[41px]
    hover:!bg-pink-950
    !bg-primary-color
    text-white;
}
.add-role-title-row {
  @apply grid
    md:grid-cols-2
    grid-cols-1
    w-full
    gap-x-7
    gap-y-3
    border-b
    pb-7
    border-gray-200/50
  /* dark:border-gray-200/20 */;
}
.add-role-is-active-label {
  @apply text-black
    text-opacity-70
    font-light
  /* dark:text-white/80 */;
}
.add-role-permissions-title {
  @apply text-black
    text-opacity-70
    font-bold
    md:mb-5
    mb-4
  /* dark:text-white/90 */;
}

.add-role-top-container {
  @apply bg-white
    rounded-primary-radius
    p-7
    space-y-7
  /* dark:bg-gray-900 */;
}
