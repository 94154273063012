@tailwind components;

@layer components {
  .delete-model-body {
    @apply flex
        flex-col
        items-center
        text-center
        relative;
  }
  .delete-model-body-header {
    @apply flex
        justify-end
        w-full;
  }
  .delete-model-body-header-box,
  .delete-model-body-header-box-warning {
    @apply cursor-pointer
        flex
        justify-center
        items-center
        rounded-full
        w-7
        h-7
        /* bg-light-failure-color */
        bg-[#f7f7f7]
        mb-6;
  }
  /* .delete-model-body-header-box-warning {
    @apply bg-warning-color;
  } */
  .delete-model-body-icon-box,
  .delete-model-body-icon-box-warning {
    @apply flex
        bg-failure-color-03
        justify-center
        items-center
        rounded-primary-radius
        w-16
        h-16
        /* bg-light-failure-color */
        mb-6;
  }
  .delete-model-body-icon-box-warning {
    @apply bg-warning-color-03;
  }
  .delete-model-body-icon-box-description {
    @apply mb-[30px];
  }
}
