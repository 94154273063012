.grider {
  @apply grid
    lg:grid-cols-2
    grid-cols-1
    gap-x-6
    gap-y-4
    w-full;
}
.titleText {
  @apply text-base
    text-black
    font-semibold
  /* dark:text-white */;
}
.fromContainer {
  @apply bg-gray-300/20
    rounded-lg
    p-5
    flex
    lg:flex-row
    flex-col
    justify-between
    h-52
    min-w-[250px]
    w-full
  /* dark:bg-gray-300/5 */;
}
.toContainer {
  @apply flex
    flex-col
    gap-y-4
    bg-gray-300/20
    rounded-lg
    p-5
    h-fit
    min-h-[208px]
    min-w-[250px]
    w-full
  /* dark:bg-gray-300/5 */;
}
.boldText {
  @apply text-black/70
    font-semibold
  /* dark:text-gray-300 */;
}
.normalText {
  @apply text-black/70
  /* dark:text-gray-400 */;
}
.col {
  @apply flex
    flex-col
    gap-y-3;
}
.AddressFormButton {
  @apply bg-gray-200/75
    hover:bg-gray-300
    whitespace-nowrap
    text-sm
    text-third-color
    h-fit
    w-fit
    px-5
    font-bold
  /* dark:text-slate-400 */
  /* dark:bg-slate-700/60 */
  /* dark:hover:bg-slate-700 */;
}
