@tailwind components;

@layer components {
  .form-box-container {
    @apply w-full
      p-[30px]
      shadow-md
      rounded-[20px]
      bg-white;
  }
  .employee-header-img-row {
    @apply grid
        grid-cols-1
        lg:grid-cols-3
        gap-7;
  }
  .employee-export-btn {
    @apply !bg-third-color
      text-white
      !w-[90px]
      md:!w-[123px]
      h-[39px]
      flex
      text-sm
      font-semibold;
  }
  .employee-action-show-btn {
    @apply !bg-third-color
      text-white
      text-sm
      w-[100px]
      h-[41px]
      flex-row-reverse
      flex
      gap-2;
  }
  .employee-add-btn {
    @apply !bg-main-color
      text-white
      h-10
      !w-full
      p-0
      md:!w-[151px]
      mt-5
      md:mt-0;
  }
  .employee-header-img-row-inputs {
    @apply col-span-2
        space-y-[30px]
        order-2 lg:order-1;
  }
  .employee-box-row {
    @apply grid
        grid-cols-1
        md:grid-cols-2
        gap-[30px]
        mt-[30px];
  }
  .form-box {
    @apply grid
        grid-cols-1
        md:grid-cols-2
        gap-[30px];
  }
  .is_active_description {
    @apply text-sm
        font-light
        text-light-black-color-07
        leading-normal
        /* -ml-2  */
        mt-1;
  }
  .employee-update-save {
    @apply !bg-main-color
      w-[117px]
      h-[41px]
      text-white
      text-sm
      font-semibold;
  }
  .employee-add-save {
    @apply !bg-main-color
      w-[117px]
      h-[41px]
      text-white
      text-sm
      font-semibold;
  }
}
