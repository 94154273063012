@tailwind components;

@layer components {
  .upload-avatar-container {
    @apply !bg-light-gray
      opacity-25	
      w-[331px]
      h-[251px]
      hover:opacity-50
      rounded-[100%];
  }

  .upload-item-container {
    @apply !bg-primary-color-3
      w-[331px]
      h-[251px]
      rounded-[20px]
    /* dark:hover:!bg-opacity-50 */
    /* dark:!bg-opacity-20 */
    /* dark:!bg-gray-700 */;
  }

  .icon-image {
    @apply w-20
      h-16;
  }

  .upload-image-error {
    @apply flex
      justify-center
      items-center
      gap-2
      text-sm
      mt-2
      text-failure-color
      absolute
      w-full;
  }
}
