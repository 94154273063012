@tailwind components;

@layer components {
  .cell-type-box {
    @apply gap-y-1.5	
    ml-4
    capitalize	
    truncate
    flex
    justify-between
    flex-col;
  }
  .cell-type-label {
    @apply text-base
      font-bold
      text-black
      opacity-90
      whitespace-nowrap;
  }
  .cell-type-value-box {
    @apply flex gap-1 text-sm
    font-normal	
    opacity-70
    whitespace-nowrap;
  }
}
