@tailwind components;

@layer components {
  .cell-img-container {
    @apply flex;
  }
  .cell-img-box {
    @apply flex-shrink-0
      w-10
      mx-1
      h-10;
  }
  .cell-img-box-image {
    @apply w-full
      h-full
      rounded-md
      object-cover
      object-center;
  }
  .cell-img-name-box {
    @apply ml-4
      capitalize	
      truncate
      flex
      justify-between
      flex-col;
  }
  .cell-img-name-box-name {
    @apply text-base
      font-bold
      text-black
      opacity-90
      whitespace-nowrap;
  }
  .cell-img-country {
    @apply text-sm
      text-main-color
      font-normal
      opacity-90
      whitespace-nowrap
      /* dark:text-main-color */;
  }
}
