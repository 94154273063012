@tailwind components;

@layer components {
  .img-selector-input-container {
    @apply rounded-primary-radius
      bg-input-color
      w-full
      md:px-4
      px-2
      md:py-2.5
      py-2
      flex
      items-center
      justify-between
      cursor-pointer
      gap-1
      /* dark:bg-gray-800 */
      /* dark:text-white */;
  }

  .img-selector-chosen-item {
    @apply whitespace-nowrap
      w-full
      text-third-color
      font-semibold
      flex
      gap-x-2
      items-center
      text-sm;
  }
  .img-selector-chosen-item-img-container {
    @apply w-8
      rounded-lg
      aspect-square
      relative;
  }

  .img-selector-chosen-item-img {
    @apply rounded-lg
      object-cover
      object-center;
  }

  .img-selector-chosen-item-text {
    @apply text-third-color
      opacity-40
      whitespace-nowrap
      w-full;
  }

  .img-selector-search-sub-container {
    @apply flex
      items-center
      bg-input-color
      w-full
      px-2
      rounded-primary-radius;
  }

  .img-selector-selected-list-bg {
    @apply bg-primary-color
      rounded-lg
      opacity-5
      px-1
      absolute
      w-[100.5%]
      h-full
      top-0
      left-0;
  }
  .selector-chevron-icon {
    @apply duration-300 
      h-3
      /* dark:text-white */;
  }

  .img-selector-box-container {
    @apply absolute
      w-full
      bg-white
      z-10
      rounded-primary-radius
      mt-2
      overflow-y-auto
      /* dark:bg-gray-800 */;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  }

  .img-selector-search-container {
    @apply sticky
    top-0
    flex
    items-center
    bg-white
    z-10
    -mb-2
    pt-5
    pb-3
    px-5     
      /* dark:bg-gray-800 */;
  }

  .img-selector-search-input {
    @apply placeholder:text-gray-400
      placeholder:text-sm
      focus:outline-none
      p-2
      outline-none
      bg-input-color
      w-full
    /* dark:text-white */
    /* dark: bg-gray-800; */;
  }

  .img-selector-list-input {
    @apply px-4
      py-2.5
      text-base
      font-light
      border-main-color
      hover:border-r-4
      hover:pr-[12px]
      rtl:hover:border-r-0
      rtl:hover:border-l-4
      hover:text-main-color
      cursor-pointer
      /* dark:text-white */;
  }
}
