@tailwind components;

@layer components {
    .notification-head-container{
      @apply bg-white

dark:bg-gray-900


rounded-xl
w-full

 
    }
.notification-box {
    @apply
    flex
    flex-col
    lg:flex-row
    md:flex-row
    gap-4
    px-8
    cursor-pointer
    
    border-[#E5E5E5]/[90%]
    py-5
    items-center
}
.notification-img{
    @apply
    w-[60px] 
    h-[60px]
}
.notification-text-container{
    @apply
    flex
     flex-col
}
.notification-text{
    @apply
    
    flex 
    flex-row
   gap-3
    items-center
    
}
.notification-text-span{
    @apply
    flex
    flex-col
    lg:flex-row
    md:flex-row
    gap-1
    items-center
   
    
    
    
}
.notification-name{
    @apply
    text-base
    font-bold
    whitespace-nowrap
}
.notification-desc{
    @apply
    text-base
    font-normal
    
}
.notification-active{
    @apply
    text-sm
    font-bold
    text-gray-color
}
.notification-new{
    @apply
    text-secondary-color
    text-sm
    font-semibold
    bg-[#A73A7B]/[5%]
    rounded
    
    py-2
    px-5
    
}


}