@tailwind components;

@layer components {
  .table-normal-container {
    @apply px-5
      py-5
      text-sm;
  }
  .table-normal-first-text {
    @apply text-gray-900
      whitespace-nowrap
      font-semibold
      text-base
    /* dark:text-gray-200 */;
  }
  .table-normal-second-text {
    @apply text-gray-600
      whitespace-nowrap
    /* dark:text-gray-400 */;
  }
}
