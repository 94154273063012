.topContainer {
  @apply grid
    lg:grid-cols-2
    grid-cols-1
    lg:gap-y-0
    gap-y-6
    gap-x-5;
}

.PrimaryIconContainer {
  @apply w-9
    h-9
    aspect-square
    flex
    justify-center
    rounded-lg
    items-center
    cursor-pointer
    transition-colors
    duration-300
    relative;
}

.ThirdIconContainer {
  @apply w-10
    h-10
    aspect-square
    flex
    justify-center
    rounded-lg
    items-center
    bg-none
    cursor-pointer
    bg-input-color
    hover:bg-gray-200/50
    transition-colors
    duration-300
    relative
    /* dark:bg-third-color */
    /* dark:hover:bg-slate-700 */;
}

.primaryIcon {
  @apply text-primary-color
    font-bold
    text-lg
    z-10
  /* dark:text-gray-100 */;
}

.barsIcon {
  @apply text-third-color
    font-bold
    text-lg
  /* dark:text-gray-100 */;
}

.subTotal {
  @apply flex
    justify-between
    font-bold
    opacity-80
    text-gray-800
  /* dark:text-gray-100 */;
}

.centerBetween {
  @apply flex
    justify-between
    items-center;
}

.addExtra {
  @apply text-gray-800
    text-sm
    font-semibold
    opacity-90
  /* dark:text-gray-100 */;
}

.rowGridContainer {
  @apply grid
    grid-cols-5
    w-full
    md:gap-6
    gap-x-1;
}

.firstRow {
  @apply flex
    md:gap-x-4
    col-span-4
    gap-x-2;
}

.totalContainer {
  @apply flex
    flex-row
    justify-between
    w-full
    rounded-[15px]
    bg-gray-300
    bg-opacity-10
    border
    border-gray-300
    border-opacity-30
    p-5;
}

.totalContainerLeft {
  @apply flex
    flex-col
    gap-y-8
    items-start;
}

.totalContainerRight {
  @apply flex
    flex-col
    gap-y-8
    items-center;
}

.totalContainerText {
  @apply font-semibold
    text-gray-800
    opacity-80
    flex
    items-center
    justify-end
    col-span-1
  /* dark:text-gray-100 */;
}

.totalContainerTotal {
  @apply flex
    items-center
    gap-x-2;
}

.usd {
  @apply text-primary-color
    px-[15px]
    py-[5px]
    text-sm
    font-semibold
    rounded-lg
    relative
    /* dark:text-gray-100 */;
}

.paidAmount {
  @apply bg-white
  text-black
  text-opacity-50
  border
  border-gray-300
  border-opacity-50
  px-5
  py-[5px]
  rounded-lg
  /* dark:bg-gray-700 */
  /* dark:text-gray-300 */
  /* dark:border-gray-800 */;
}

.hr {
  @apply h-[1px]
  border-none
  bg-gray-300/30
  /* dark:bg-gray-700/20; */;
}

.primaryBg {
  @apply bg-primary-color
absolute
top-0
left-0
opacity-5
w-full
h-full
rounded-lg
/* dark:bg-primary-color */
/* dark:opacity-100 */;
}
