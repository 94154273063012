.roles-index-action-btn {
  @apply !bg-primary-color
    p-5 
    text-white
    !h-10
    w-full
    mt-5
    md:mt-0;
}

.roles-export-btn {
  @apply !bg-third-color
    text-white
    font-light
    flex
    text-sm
    font-semibold
    !w-[90px]
    md:!w-[123px]
    !h-[39px];
}
