@tailwind components;

@layer components {
  .shipping-create-btn {
    @apply !bg-main-color
    !whitespace-nowrap
    !px-2
    !text-white
    !h-10
    !w-full
    md:!w-[151px]
    !mt-5
    md:!mt-0;
  }

  .shipping-export-btn {
    @apply !bg-third-color
    !text-white
    !w-[90px]
    md:!w-[123px]
    !h-[39px]
    !flex
    !text-sm
    !font-semibold;
  }

  .shipping-update-btn {
    @apply !bg-main-color
    !whitespace-nowrap
    !px-2
    !text-white
    !h-10
    !w-full
    md:!w-[151px]
    text-sm
    !mt-5
    md:!mt-0;
  }

  .shipping-is_action-description {
    @apply text-sm
    text-black/70
    ml-1
    !whitespace-normal;
  }

  .shipping-action-btn {
    @apply !bg-third-color
    text-white
    text-sm
    w-[100px]
    h-[41px]
    flex-row-reverse
    flex
    gap-2;
  }

  .shipping-form {
    @apply rounded-primary-radius
    p-7
    space-y-7
    bg-white;
  }
}
