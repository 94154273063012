@tailwind components;

@layer components {
  .modal-screen {
    @apply 
      z-50 
      fixed 
      inset-0 
      overflow-y-auto 
      overflow-x-hidden 
      outline-none 
      focus:outline-none;
  }
  .modal-box-layout {
    @apply relative 
      w-auto 
      my-6 
      max-w-[80%] 
      lg:max-w-[50%] 
      mx-auto 
      max-w-3xl;
  }
  .modal-box {
    @apply bg-white 
      flex 
      flex-col 
      w-full 
      relative 
      border-0 
      rounded-primary-radius
      shadow-lg 
      outline-none 
      focus:outline-none;
  }
  .modal-title {
    @apply
      text-xl 
      font-semibold;
  }
  .modal-transparent-background {
    @apply opacity-25 
      fixed 
      inset-0 
      z-40 
      bg-black;
  }
}
