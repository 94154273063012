@tailwind components;

@layer components {
  .expenses-type-create-btn {
    @apply !bg-main-color
        text-white
        h-10
        w-full
        p-5
        md:w-[151px]
        mt-5
        md:mt-0;
  }
  .expenses-type-export-btn {
    @apply !bg-third-color
        text-white
        !w-[90px]
        md:!w-[123px]
        h-[39px]
        flex
        text-sm
        font-semibold;
  }
  .expenses-type-save-update-btn {
    @apply text-white
        !bg-main-color
        w-[117px]
        h-[41px]
        text-sm
        font-semibold;
  }
  .expenses-type-show-action-btn {
    @apply !bg-third-color
        text-white
        px-[20px]
        py-[10px];
  }
  .expenses-type-save-add-btn {
    @apply !bg-main-color
        text-white
        w-[117px]
        h-[41px];
  }
}
