@tailwind components;

@layer components {
  .select-pagination-container {
    @apply flex 
      items-center
      my-5
      gap-5;
  }

  .select-pagination-input-container {
    @apply rounded-md
      bg-third-color-05
      w-fit
      px-[10px]
      py-[2px]
      gap-x-1
      flex
      items-center
      justify-between
      cursor-pointer
      /* dark:bg-gray-800 */
      /* dark:text-white */;
  }

  .select-pagination-box-container {
    @apply absolute
      w-fit
      bg-white
      z-10
      rounded-primary-radius
      overflow-y-auto
      bottom-10
      /* dark:bg-gray-800 */;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .select-pagination-list-input {
    @apply px-4
      py-1.5
      text-xs
      font-semibold
      border-main-color
      hover:border-r-2
      rtl:hover:border-r-0
      rtl:hover:border-l-2
      hover:text-main-color
      cursor-pointer
      /* dark:text-white */;
  }

  .select-pagination-label {
    @apply text-xs
      /* font-title-font */
      font-normal
    /* font-light */
    /* opacity-30; */;
  }

  .select-pagination {
    @apply cursor-pointer
      bg-opacity-20
      border-none
      focus:outline-none
      focus:ring-2
      focus:ring-main-color
      rounded-md
      text-xs
      py-1
      px-2
      font-normal;
  }
}
