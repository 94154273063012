@tailwind components;

@layer components {
  .cell-name-box {
    @apply gap-y-1.5	
    ml-4
    capitalize	
    truncate
    flex
    justify-between
    flex-col;
  }
  .cell-name-role-name {
    @apply text-base
      font-bold
      text-black
      opacity-90
      whitespace-nowrap;
  }
  .cell-name-role {
    @apply text-sm
      font-bold
      opacity-80
      whitespace-nowrap
      /* dark:text-main-color */;
  }
}
