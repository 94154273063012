@tailwind components;

@layer components {
  .checkbox {
    @apply rounded 
      w-4 
      h-4 
      disabled:!bg-checkbox-disabled-color
      checked:bg-gray-600
      border-gray-600
      absolute
      top-1/2
      left-1/2
      transform
      -translate-x-1/2
      -translate-y-1/2
      appearance-none
      bg-gray-color
      duration-500;
  }

  .checkbox-label {
    @apply text-sm
      font-normal
      text-gray-900
    /* dark:text-gray-300; */;
  }

  .checkbox-icon {
    @apply absolute
      top-1/2
      left-1/2
      transform 
      -translate-x-1/2
      -translate-y-1/2
      duration-300
      font-semibold
      text-white
      text-[10px];
  }

  .checkbox-label-before {
    @apply mr-4
      rtl:ml-4
      rtl:mr-0;
  }

  .checkbox-label-after {
    @apply ml-4
      rtl:mr-4
      rtl:ml-0;
  }
}
