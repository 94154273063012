@tailwind components;

@layer components {
  .pagination-button {
    @apply gap-4 
      rounded-primary-radius 
      font-bold 
      duration-300 
      py-2.5 
      w-full
      px-12;
  }

  .pagination-container {
    @apply flex 
      items-center
   /* h-20; */;
  }
  .control-pagination {
    @apply p-2 
      m-1 
      h-[31px]
      w-[31px]
      cursor-pointer 
      border-0 
      rounded-md 
      text-black-color-07
      transition 
      duration-300
      select-none 
      bg-gray-color-2;
  }
  .control-pagination-disabled {
    @apply text-gray-300
      cursor-no-drop;
  }

  .body-pagination {
    @apply flex 
      items-center 
      h-8;
  }

  .dots-pagination {
    @apply px-3
      h-8
      text-center
      mx-1
      text-black
      flex
      items-center
      box-border
      align-middle
      leading-4
      tracking-tight
      rounded-md
      text-xs
      min-w-[2px]
      text-black-color-07
      bg-gray-color-2;
  }

  .dots-pagination.dots:hover {
    @apply bg-transparent
      cursor-default;
  }

  .dots-pagination:hover {
    @apply bg-opacity-25
      bg-gray-200
      cursor-pointer;
  }

  .dot-pagination {
    @apply h-[31px]
      w-[31px]
      rounded-md
      border-0
      m-1
      font-normal
      text-sm
      cursor-pointer
      transition
      select-none;
  }

  .dot-pagination:hover {
    @apply bg-primary-color
      bg-opacity-40
      text-white;
  }
}
