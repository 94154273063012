.parentContainer {
  @apply bg-white
    rounded-primary-radius
    md:p-7
    p-3
    space-y-7
  /* dark:bg-gray-900 */;
}
.addRecurringDraftBtn {
  @apply hover:!bg-gray-300
    !bg-gray-200
    whitespace-nowrap
    text-sm
    text-third-color
    h-fit
    w-fit
    px-5
    font-bold
  /* dark:hover:!bg-slate-700 */
  /* dark:!bg-slate-700/60 */
  /* dark:text-slate-400 */;
}
.addRecurringBtn {
  @apply w-fit
    h-fit
    px-8
    py-2.5
    hover:!bg-pink-950
    !bg-primary-color
    text-white;
}
.topRow {
  @apply flex
    lg:flex-row
    flex-col
    w-full
    gap-4;
}
.textInputsContainer {
  @apply flex
    flex-col
    gap-y-4
    justify-between
    items-start
    w-full
    lg:order-1
    order-2;
}
.imageInputContainer {
  @apply lg:order-2
    order-1
    flex
    justify-center;
}
.selectionsRow {
  @apply flex
    md:flex-row
    flex-col
    items-end
    gap-x-5
    gap-y-3
    w-full;
}

.miniDivider {
  @apply h-[1px]
    md:hidden
    border-none
    bg-gray-300/30
    /* dark:bg-gray-700/20 */;
}
