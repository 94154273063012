@tailwind components;

@layer components {
  .navbar {
    @apply md:hidden
      relative
      order-2
      h-20
      w-full
      flex
      items-center
      justify-between
      p-5
      bg-white
      /* dark:bg-gray-800 */;
  }

  .sidebar-container {
    @apply absolute
      transition-transform
      md:relative 
      top-0 left-0
      bg-white 
      duration-300 z-40;
  }
  .sidebar-list-container {
    @apply overflow-y-scroll 
      rtl:pr-[50px] 
      rtl:pl-0 pl-[50px]
      duration-300;
  }

  .open-sidebar-icon {
    @apply hover:opacity-50
      inline-flex
      items-center
      p-2
      mt-2
      ml-3
      text-sm
      text-gray-500
      rounded-primary-radius
      hover:bg-gray-100
      focus:outline-none
      focus:ring-2
      focus:ring-gray-200
      /* dark:text-gray-400 */
      /* dark:hover:bg-gray-700 */
      /* dark:focus:ring-gray-600 */;
  }

  .close-sidebar-icon {
    @apply md:hidden
      absolute
      hover:opacity-50
      right-1
      inline-flex
      items-center
      p-2
      mt-2
      ml-3
      text-sm
      text-gray-500
      rounded-primary-radius
      focus:outline-none
      duration-300
      /* dark:text-gray-400 */
      /* dark:hover:bg-gray-700 */;
  }

  .mobilenavbar-open {
    @apply z-20
      bg-white
      shadow-lg
      h-screen
    /* dark:bg-gray-900  */;
  }

  .navigator-container {
    @apply md:flex
      cursor-pointer
      z-[-1]
      flex
      justify-end
      items-center
      h-10
      w-16
      bg-white
      rounded-full
      absolute
      -right-0
      rtl:-left-0
      rtl:right-auto
      top-9
      group-hover/sidebar:opacity-100
      shadow-lg
      group-hover/sidebar:-right-6
      duration-500
    /* dark:text-white */
    /* dark:bg-gray-900 */;
  }

  .navigator-icon {
    @apply h-3 
      text-main-color
      cursor-pointer
      rtl:ml-2 mr-2
      rtl:group-hover/sidebar:rotate-[-450deg]
      group-hover/sidebar:rotate-[-270deg]
      duration-300
    /* dark:text-white */;
  }
  .logo-container {
    @apply inline-flex
      duration-300;
  }

  .logo-img {
    @apply rounded
      cursor-pointer
      block
      mb-[9px]
      float-left;
  }

  .full-logo-img {
    @apply w-[140px]
      h-[41px];
  }

  .collapsed-logo-img {
    @apply w-6
      h-10;
  }

  .sidebar-item {
    @apply flex
      items-center
      text-[16px]
      font-medium
      gap-x-4
      hover:text-primary-color
      opacity-50
      hover:opacity-100
      cursor-pointer
      duration-100
      /* dark:text-gray-300 */;
  }

  .sidebar-item-icon {
    @apply group-hover/item:text-main-color
      w-5
      h-[19px]
      block
      float-left
      text-light-gray
      duration-300;
  }
  .avatar-dropdown-container {
    @apply bg-gray-color-5
    /* dark:bg-gray-900  */;
  }
  .avatar-dropdown-menu {
    @apply flex
      py-3
      text-gray-800
      hover:bg-primary-color
      hover:text-white
      text-opacity-70
      /* dark:text-white */;
  }
  .dropdown-container {
    @apply flex
      pb-5 
      justify-center
      bg-gray-color-5
      cursor-pointer;
  }
  .dropdown-button {
    @apply relative
      z-10
      block
      h-11
      w-11
      rounded-full
      overflow-hidden
      border-2
      border-gray-600
      focus:outline-none
      focus:border-white;
  }

  .dropdown-img {
    @apply h-full
      w-full 
      object-cover;
  }
  .dropdown-title {
    @apply text-base 
      font-normal
      /* dark:text-white */;
  }
  .dropdown-desc {
    @apply text-sm 
      font-light
      /* dark:text-white */;
  }
  .dropdown-icon {
    @apply text-sm
      rounded-full
      p-2
      opacity-70
      bg-gray-color
      /* dark:bg-white */;
  }
  .dropdown-open {
    @apply gap-5 
      items-center 
      flex-1;
  }
  .avatardropdown-icon {
    @apply group-hover/item:text-primary-color 
      text-base 
      font-light
      flex-1;
  }

  .sidebar-item-label {
    @apply group-hover/item:text-main-color
      text-[16px]
      leading-[21px]
      font-[300]
      flex-1
      duration-300;
  }
  .submenu-item {
    @apply text-main-color
      p-2
      hover:bg-opacity-10
      hover:bg-main-color
      hover:text-white
      text-sm
      flex
      items-center
      gap-x-4
      cursor-pointer
      m-2
      mx-5
      rounded-md
      /* dark:text-gray-300 */
      /* dark:hover:bg-gray-700 */;
  }
  .submenu-icon {
    @apply group-hover/item:text-main-color
      duration-300;
  }
  .sidebar-line {
    @apply hidden 
      absolute 
      right-0
      rtl:left-0
      rtl:right-auto
      w-1 
      h-8	
      bg-primary-color 
      transition-all 
      duration-300;
  }
  .active-dot {
    @apply rounded-full 
    bg-primary-color 
    w-2 
    h-2 
    absolute 
    -right-4 
    duration-300;
  }
}
