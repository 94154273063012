.titleText {
  @apply text-gray-800
    opacity-80
  /* dark:text-white */;
}

.itemsGrider {
  @apply w-full
    lg:gap-x-6
    gap-x-2
    grid
    lg:flex
    grid-cols-7
    gap-y-2
    lg:grid-rows-1
    grid-rows-2;
}

.addItemTopContainer {
  @apply flex
    items-start
    lg:gap-x-5
    gap-x-2
    lg:border-none
    border-b
    lg:pb-0
    pb-7
    /* dark:border-gray-300/10 */;
}

.inputsContainer {
  @apply flex
    lg:flex-row
    flex-col
    lg:gap-x-4
    gap-x-2
    gap-y-2.5;
}

.row {
  @apply flex
    gap-x-1
    md:gap-x-2
    items-end;
}

.IconContainer {
  @apply w-10
    h-10
    aspect-square
    flex
    justify-center
    rounded-lg
    items-center
    bg-red-50
    hover:bg-red-100
    transition-colors
    duration-300
    cursor-pointer
    /* dark:bg-failure-color */
    /* dark:hover:bg-failure-color */;
}

.icon {
  @apply text-failure-color
    font-bold
  /* dark:text-gray-100 */;
}

.AddInvoiceRowButtons {
  @apply w-fit
    h-fit
    px-8
    py-2.5
    bg-primary-color
    hover:bg-pink-950
    text-white
    whitespace-nowrap;
}
