@tailwind components;

@layer components {
  .custom-selector-btn {
    @apply w-[90px]
      md:w-[113px]
      h-10
      rounded-primary-radius
      text-third-color
      text-sm
      font-semibold
      flex
      items-center
      justify-center
      cursor-pointer
      gap-1
      md:gap-4
      select-none	
      leading-normal
      bg-third-color-05;
    /* dark:bg-gray-800 */
    /* dark:text-white */
    /* md:px-4 */
    /* px-2 */
    /* md:py-2.5 */
    /* py-2 */
  }
  .custom-selector-btn-opened {
    @apply bg-white
      rounded-md
      shadow-md;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  }
  .custom-selector-btn-icon {
    @apply h-4
      w-4
      /* dark:text-white; */;
  }
  .custom-selector-container-list {
    @apply absolute
      bg-white
      z-10
      mt-2
      rounded-lg
      shadow-md
      p-5
      w-[345px]
      flex
      flex-col
      gap-6
      /* dark:bg-gray-800 */;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
    /* animation: pulse 0.7s; */
  }
}

/* @keyframes custom-selector-container-list-Frames {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */
