@tailwind components;

@layer components {
  .input-text-container {
    @apply flex 
      rounded-primary-radius
      p-1
      bg-input-color
      relative
    /* dark:bg-gray-800 */;
  }

  .tag {
    @apply bg-primary-color-5
     text-main-color
      rounded-[4px]
      px-2.5
      py-1.5;
  }

  .input-tag {
    @apply px-4
      py-1
      border-none
      placeholder:text-[14px]
      placeholder:leading-[20px]
      placeholder:font-[400]
      bg-transparent
      text-gray-900
      outline-none
      focus:outline-none
      w-full
      /* dark:text-white */;
  }

  .input-tag::-webkit-outer-spin-button,
  .input-tag::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  .input-tag[type="number"] {
    -moz-appearance: textfield;
  }

  .input-error-msg {
    @apply absolute
      flex
      items-center
      gap-2
      text-sm
      mt-10
      text-failure-color;
  }

  .input-text {
    @apply px-4
      py-1
      placeholder:text-sm
      placeholder:font-normal
      bg-transparent
      text-gray-900
      !outline-none
      focus:outline-none
      w-full
      /* dark:bg-gray-800 */
      /* dark:outline-gray-800 */
      /* dark:text-white */;
  }

  .input-tag {
    @apply md:px-4
      px-2
      py-1
      border-none
      placeholder:text-[14px]
      placeholder:leading-[20px]
      placeholder:font-[400]
      bg-transparent
      text-gray-900
      outline-none
      focus:outline-none
      w-full;
  }

  .input-dropdown-button {
    @apply flex
      items-center
      text-[0.8rem]
      leading-[20px]
      font-[300]
      gap-3
      rtl:border-l-2 rtl:border-r-0 border-r-2
      md:px-3
      px-2
      bg-input-color
      /* dark:bg-[#1f2937] */
      /* dark:text-white */
      rtl:flex-row-reverse;
  }

  .input-dropdown-list {
    @apply overflow-y-auto
      absolute
      top-8
      shadow-md
      bg-white
      p-2
      text-start
      w-full
      z-20
      mt-3
      rounded-primary-radius
      duration-300
      /* dark:bg-gray-800 */
      /* dark:text-white */;
  }

  .input-error-msg {
    @apply absolute
      text-failure-color
      top-9
      flex
      items-center
      gap-2
      text-sm
      mt-3;
  }
}
