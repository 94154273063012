@tailwind components;

@layer components {
  .custom-table-row {
    @apply border-gray-100
      w-full
      cursor-pointer
      align-middle;
  }
}
