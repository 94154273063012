@tailwind components;

@layer components {
  .selector-input-container {
    @apply rounded-primary-radius
      bg-input-color
      w-full
      md:px-4
      px-2
      md:py-2.5
      py-2
      flex
      items-center
      justify-between
      cursor-pointer
      /* dark:bg-gray-800 */
      /* dark:text-white */;
  }

  .selector-chevron-icon {
    @apply duration-300 
      h-3
      /* dark:text-white */;
  }

  .selector-list {
    @apply bg-input-color
      z-10
      rounded-main-radius
      mt-2
      overflow-y-auto
      duration-300
      /* dark:bg-gray-800 */;
  }

  .selector-box-container {
    @apply absolute
      w-full
      bg-white
      z-10
      rounded-primary-radius
      mt-2
      overflow-y-auto
      /* dark:bg-gray-800 */;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  }

  .selector-search-container {
    @apply h-10
      flex
      items-center
      px-2
      m-5
      sticky
      top-0
      bg-input-color
      rounded-lg
      /* dark:bg-gray-800 */;
  }

  .selector-search-input {
    @apply placeholder:text-gray-400
    placeholder:text-sm
    p-2
    outline-none
      bg-input-color
      w-full
    /* dark:text-white */
    /* dark: bg-gray-800; */;
  }

  .selector-list-input {
    @apply p-5
      text-base
      font-light
      border-main-color
      hover:border-r-4
      rtl:hover:border-r-0
      rtl:hover:border-l-4
      hover:text-main-color
      cursor-pointer
      /* dark:text-white */;
  }

  .selector-option {
    @apply p-2
    text-sm
    border-main-color
    hover:border-r-4
    hover:text-main-color
    /* dark:text-white */;
  }

  .selector-option-hover {
    @apply bg-main-color
      text-white
      hover:text-white;
  }
}
