.selectBtn {
  @apply whitespace-nowrap
    select-none
    flex
    items-center
    text-third-color
    text-sm
    cursor-pointer
    px-4
    py-1.5
    rounded-[5px]
    w-fit
    font-semibold
    transition-colors
    duration-200
    /* dark:text-white/90 */;
}

.selectBtnOpened {
  @apply shadow-[0_0_15px_0_rgba(0,0,0,0.20)]
    bg-white/80
    hover:bg-gray-50/5
  /* dark:hover:bg-black/50 */
  /* dark:bg-black/30 */;
}

.selectBtnClosed {
  @apply hover:bg-[#414E5F]/10
    bg-[#414E5F]/5
    /* dark:bg-third-color*/
    /* dark:hover:bg-[#414E5F]/50  */;
}

.selectBtnIcon {
  @apply sm:mr-2 
    rtl:sm:ml-2 
    rtl:sm:mr-0 
    sm:my-0
    my-1
    mr-0;
}

.selectBtnUl {
  @apply md:w-80
    w-60
    min-w-fit
    absolute
    right-0
    bg-white
    shadow-[0_0_20px_0_rgba(0,0,0,0.10)]
    px-5
    z-20
    rounded-primary-radius
    mt-2
    overflow-y-auto
    transition-all
    duration-500
    /* dark:shadow-[0_0_20px_0_rgba(0,0,0,0.70)] */
    /* dark:bg-gray-800 */;
}

.ulOpened {
  @apply max-h-[330px]
    h-fit;
}

.ulClosed {
  @apply max-h-0
    !py-0;
}

.selectSearchContainer {
  @apply sticky
    top-0
    bg-white
    z-10
    -mx-5
    -mb-2
    pt-5
    pb-3
    px-5
    /* dark:bg-gray-800 */;
}

.selectsearch {
  @apply w-full
    py-[11px]
    px-3
    rounded-primary-radius
    bg-input-color
    focus:outline-none
    /* dark:bg-gray-700 */
    /* dark:caret-white */
    /* dark:text-white */;
}

.emptyItemsMessage {
  @apply text-black/30
    py-2
    /* dark:text-white/30 */;
}

.selectListItems {
  @apply cursor-pointer
    px-2
    -mx-2
    rounded-[3px]
    my-2
    py-3
    border-r-main-color
    hover:bg-gray-500/10
    transition-all
    whitespace-nowrap
    /* dark:text-white */;
}

.selectListItemsSelected {
  @apply !text-main-color
    font-semibold
    border-r-4;
}

.listItemDivider {
  @apply border-b-[1px]
    border-[#E5E5E5]/50
    /* dark:border-[#E5E5E5]/5 */;
}

/* @keyframes slide {
  0% {
    margin-bottom: 12px;
    opacity: 1;
    height: fit-content;
    overflow: auto;
  }
  50% {
    margin-bottom: 6px;
    opacity: 1;
    height: fit-content;
    overflow: auto;
  }
  100% {
    margin-bottom: 0;
    opacity: 0;
    z-index: -1;
    height: 0;
    overflow: hidden;
  }
}
.animate-slide {
  animation-name: slide;
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
} */
